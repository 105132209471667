import request from '@/utils/request'
export function giftinfo(params) {
  return request({
    url: '/user/Gift/getGiftInfo',
    method: 'get',
    params: params
  })
}
// 判断筑龙币够不
export function getMyScore(params) {
  return request({
    url: '/user/Gift/getMyScore',
    method: 'get',
    params: params
  })
}

// 原创list
export function list(params) {
  return request({
    url: '/gift/gift/exchangeGift',
    method: 'get',
    params: params
  })
}
// 获取分类id
export function classlist(params) {
  return request({
    url: '/gift/gift/getCategoryList',
    method: 'get',
    params: params
  })
}

// 设为默认地址 、删除地址【郑勤财】
export function updateAddress(params) {
  return request({
    url: '/user/address/updateAddress',
    method: 'get',
    params: params
  })
}
// 获取省市县列表【郑勤财】
export function getPCCList(params) {
  return request({
    url: '/user/address/getPCCList',
    method: 'get',
    params: params
  })
}
// 获取单个收货地址详情【郑勤财】
export function getOneAddressInfo(params) {
  return request({
    url: '/user/address/getOneAddressInfo',
    method: 'get',
    params: params
  })
}
// 省列表
export function getProvince(params) {
  return request({
    url: '/user/Jdaddress/getProvince',
    method: 'get',
    params: params
  })
}
// 市列表
export function getCity(params) {
  return request({
    url: '/user/Jdaddress/getCity',
    method: 'get',
    params: params
  })
}
// 获取区列表
export function getCounty(params) {
  return request({
    url: '/user/Jdaddress/getCounty',
    method: 'get',
    params: params
  })
}
// 街道
export function getTown(params) {
  return request({
    url: '/user/Jdaddress/getTown',
    method: 'get',
    params: params
  })
}
// 原创新增、修改
export function editAddress(params) {
  return request({
    url: '/user/address/editAddress',
    method: 'get',
    params: params
  })
}
// 添加新地址
export function addAddress(params) {
  return request({
    url: '/user/Jdaddress/editAddress',
    method: 'get',
    params: params
  })
}
// 收货地址列表接口 京东
export function addressList(params) {
  return request({
    url: '/user/Jdaddress/getUserJdAddressList',
    method: 'get',
    params: params
  })
}
// 原创
export function getUserAddressList(params) {
  return request({
    url: '/user/address/getUserAddressList',
    method: 'get',
    params: params
  })
}
// 删除地址
export function deladdress(params) {
  return request({
    url: '/user/Jdaddress/deleteAddress',
    method: 'get',
    params: params
  })
}
// 获取每个颜色下对应的规格列表
export function gelist(params) {
  return request({
    url: '/user/Gift/getSpecsByColor',
    method: 'get',
    params: params
  })
}
// 设置默认地址
export function mraddress(params) {
  return request({
    url: '/user/Jdaddress/setDefaultAddress',
    method: 'get',
    params: params
  })
}
// 兑换接口
export function exchage(params) {
  return request({
    url: '/user/Gift/exchangeGift',
    method: 'get',
    params: params
  })
}
